<template>
  <div class="agent--container">
    <div class="row" v-if="isLoading">
      <div class="col-12">
        <h4 class="text-center">{{ $t('loading') }}</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-md-4 p-2" v-for="agent in agents" :key="agent.uuid">
        <card-agent :agent="agent" />
      </div>
      <client-only>
        <div class="col-12">
          <div class="w-100 wrapper--button d-flex justify-content-center">
            <pagination :meta="meta" :maxVisibleButtons="maxVisibleButtonPagination" />
          </div>
        </div>
      </client-only>
    </div>
  </div>
</template>

<script>
const CardAgent = () => import('@/components/search-agent/partials/card-agent');
const Pagination = () => import('@/components/utils/fractal-pagination');
import { mapState } from 'vuex';

export default {
  components: {
    CardAgent,
    Pagination,
  },
  data: () => ({
    maxVisibleButtonPagination: 3,
  }),
  beforeDestroy() {
    // this.$store.commit('v2/search/set_projects', { data: [], meta: null });
  },
  computed: {
    ...mapState({
      agents: (state) => state.v2.search.agents,
      meta: (state) => state.v2.search.metaAgent,
      isLoading: (state) => state.global.isLoading,
    }),
    isEmpty() {
      return !this.isLoading && this.agents && this.agents.length < 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/utils/variables';
.agent--container {
  @media #{$phones} {
    padding: 0 4px;
  }
}
</style>
